<template>
  <div v-if="form">
    <template>
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <story-view-story-info-card :story-data="form"/>
        </b-col>
      </b-row>
      <b-tabs
          v-if="form"
          pills
      >
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <span>
              <i class="WMi-info-circled-alt"/>
            </span>
            <span class="d-none d-sm-inline text-capitalize">Details</span>
          </template>
          <b-row>
            <b-col cols="12">
              <b-card>
                <b-card-body>
                  <!--                  <b-card-title>Text</b-card-title>-->
                  <!--                  <b-card-sub-title></b-card-sub-title>-->
                  <!--                    <b-list-group-item>-->
                  <!--                      <h2>Status</h2>{{ form.status }}-->
                  <!--                    </b-list-group-item>-->
                  <!--                    <hr>-->
                  <h2>Created at</h2> {{ form.created_at }}
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <span>
              <i class="WMi-user"/>
            </span>
            <span class="d-none d-sm-inline text-capitalize">User</span>
          </template>
          <b-row>
            <b-col cols="12">
              <b-card>
                <b-card-body>
                  <h2>Name</h2>{{ form.user.name }}
                  <hr>
                  <h2>Email</h2>{{ form.user.email }}
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </template>
  </div>
</template>

<script>
import {ref, onMounted} from '@vue/composition-api'
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BCard,
  BTabs,
  BTab,
  BCardSubTitle,
  BCardTitle,
  BCardBody,
} from 'bootstrap-vue'
import router from '@/router'
import StoryRepository from '@/abstraction/repository/storyRepository'
import StoryViewStoryInfoCard from './StoryViewStoryInfoCard.vue'
import StoryViewTabData from './StoryViewTabData.vue'

// repository

const storyRepository = new StoryRepository()

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BTabs,
    BTab,
    BCardSubTitle,
    BCardTitle,
    BCardBody,
    // Local Components
    StoryViewStoryInfoCard,
    StoryViewTabData,
  },
  setup() {
    const form = ref(null)
    const storyId = ref(router.currentRoute.params.id)
    onMounted(async () => {
      if (storyId.value) {
        const resource = await storyRepository.show(storyId.value)
        form.value = resource
      }
    })
    return {
      form,
    }
  },
}
</script>

<style></style>
