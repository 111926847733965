<template>
    <b-card v-if="storyData">
        <b-row>
            <!-- User Info: Left col -->
            <b-col cols="12" xl="4" class="d-flex justify-content-between">
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start align-items-center">
                    <b-avatar :src="pageData ? pageData.video.thumbnail : 'https://via.placeholder.com'" size="114px" rounded />
                    <div class="d-flex flex-column ml-1">
                        <div class="mb-1">
                            <h4 class="mb-0">
                                {{ pageData.user.name }}
                            </h4>

                            <!--              <span class="card-text">{{ pageData.description }}</span>-->
                        </div>
                        <div class="d-flex flex-wrap">
                            <!--              <b-button-->
                            <!--                :to="{ name: 'apps-story-edit', params: { id: pageData.id } }"-->
                            <!--                variant="danger"-->
                            <!--              >-->
                            <!--                Edit-->
                            <!--              </b-button>-->
                            <b-button v-if="pageData.status !== 'approved'" variant="success" class="ml-1" @click="changeStatus(pageData.id, { status: 'approved' })"> Approve it </b-button>
                            <b-button v-if="pageData.status !== 'rejected'" variant="danger" class="ml-1" @click="changeStatus(pageData.id, { status: 'rejected' })"> Reject it </b-button>
                        </div>
                    </div>
                </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col>
                <div class="d-flex justify-content-end align-items-center h-100">
                    <!--          <video-->
                    <!--              ref="videoPlayer"-->
                    <!--              class="video-js"-->
                    <!--              width="320"-->
                    <!--              height="240"-->
                    <!--              controls-->
                    <!--              :poster="pageData.thumbnail"-->
                    <!--          >-->
                    <!--            <source-->
                    <!--                :src="pageData.file.url"-->
                    <!--                type="application/x-mpegURL"-->
                    <!--            >-->
                    <!--            Your browser does not support the video tag.-->
                    <!--          </video>-->
                    <!-- <video-player :options="videoOptions"> </video-player> -->
                    <video id="videoPlayer" controls width="340" height="600" class="plyr">
                        <source :src="storyData.video.url" type="video/mp4" />
                    </video>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from "bootstrap-vue";
import router from "@/router";
import videojs from "video.js";
import useStoryList from "./useStoryList";

// repository
import StoryRepository from "@/abstraction/repository/storyRepository";
import VideoPlayer from "@/views/components/video/VideoPlayer";

const storyRepository = new StoryRepository();

export default {
    components: {
        VideoPlayer,
        BCard,
        BButton,
        BRow,
        BCol,
        BAvatar,
    },
    props: {
        storyData: {
            // type: Object,
            required: true,
        },
        options: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            pageData: this.storyData,
            videoOptions: {
                autoplay: true,
                controls: true,
                height: 250,
                width: 350,
                sources: [
                    {
                        src: this.storyData.video.url,
                        type: "application/x-mpegURL",
                    },
                ],
                poster: this.storyData.video.thumbnail,
            },
        };
    },
    methods: {
        async deleteStory(storyId) {
            await storyRepository.delete(storyId);
            router.push({ name: "apps-story-list" });
        },
        async changeStatus(storyId, data) {
            this.pageData = await storyRepository.verify(storyId, data);
            // this.$emit('reloadData')
        },
    },
    mounted(){
      console.log(this.storyData);
    }
};
</script>

<style></style>
